<template>
  <v-container class="large-alert text-center fill-height">
    <v-dialog v-model="showSetupDialog" fullscreen>
      <v-card flat class="fill-height">
        <v-card-title>
          Scanner Setup
          <v-spacer/>
          <v-btn icon outlined @click="showSetupDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="">
          To setup QR scanner, scan the following codes.
          <v-row class="text-center">
            <v-col>Code 1
              <qrcode-vue value="%%SpecCodeAA" :size=70></qrcode-vue>
            </v-col>
            <v-col>Code 2
              <qrcode-vue value="%%SpecCode99" :size=70></qrcode-vue>
            </v-col>
          </v-row>

          After scanning the above codes, the scanner will flash blue so you can pair with tablet device. When scanner
          light is solid blue, it is ready to go.
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn @click="connect">Connect</v-btn> -->
          <v-spacer/>
          <v-btn outlined @click="showSetupDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <!--      <v-row no-gutters align="start"  class="mx-0 px-0 debugcss ">-->

    <!--      </v-row>-->
    <!--      <v-spacer/>-->
    <v-row no-gutters>
      <v-col cols="1">
<!--        <v-btn outlined @click="showSetupDialog=true">Setup</v-btn>-->
      </v-col>
      <v-col>
        <!--        <h2>Meal Scan {{ date }}</h2>-->
      </v-col>
      <v-col cols="1" align-self="end">
        <v-btn icon @click="SET_HIDE_TOP_NAV({hideTopNav:!hideTopNav})">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters style="height: 90%">
      <v-col align-self="center">
        <v-alert v-if="!mealScanned" type="info" x-large>Scan Meal</v-alert>
        <v-alert v-if="mealScanned && !binScanned" type="warning" x-large>Scan Bin {{ mealScanned }}</v-alert>
        <v-alert v-if="mealScanned && binScanned && mealScanned===binScanned" type="success" x-large>Correct!
        </v-alert>
        <v-alert v-if="mealScanned && binScanned && mealScanned!==binScanned" type="error" x-large>WRONG BIN!
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import QrcodeVue from "qrcode.vue";
import {beep, error} from "@/assets/sounds";

export default {
  name: "MealScan",
  components: {
    QrcodeVue
  },
  props: {
    date: {type: String, default: null, required: false},
    initialHideNav: {}
  },

  async mounted() {
    document.addEventListener('keydown', this.keyListener, true);
    if (this.initialHideNav) {
      this.SET_HIDE_TOP_NAV({hideTopNav: true});
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyListener, true);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  data() {
    return {
      scans: [],
      buffer: '',
      mealScanned: null,
      binScanned: null,
      timeout: null,
      inputTimeout: null,
      showSetupDialog: null,
      device: null,
    }
  },
  methods: {
    ...mapMutations(['SET_HIDE_TOP_NAV']),
    async keyListener(e) {
      if (e.key === 'Enter') {
        const newItem = `${this.buffer}`;
        this.buffer = '';
        console.log('adding', newItem);
        this.processItem(newItem);
      } else if (e.key === 'Shift') {
        // skip Shift
      } else {
        this.buffer += e.key;
        if (!this.inputTimeout){
          this.inputTimeout = setTimeout(()=>{
            const newItem = `${this.buffer}`;
            this.buffer = '';
            console.log('adding', newItem);
            this.processItem(newItem);
            this.inputTimeout = null;
          },500);
        }
      }
    },
    processItem(item) {
      function getBin(item) {
        const match = item.match(/\?bin=(.+)/);
        return match && match[1];
      }

      function getTargetBin(item) {
        const match = item.match(/^target-bin=(.+)/);
        return match && match[1];
      }

      console.log('process', item);
      const mealBin = getBin(item);
      const targetBin = getTargetBin(item)

      if (mealBin) {
        this.mealScanned = mealBin;
        this.binScanned = false;
      } else if (targetBin) {
        this.binScanned = targetBin;
      } else {
        // wrong
        this.binScanned = item;
      }

      if (this.mealScanned && this.binScanned === this.mealScanned) {
        this.beep();
      }
      if (this.mealScanned && this.binScanned && this.binScanned !== this.mealScanned) {
        this.error();
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = false;
      }
      // clear scanned item
      this.timeout = setTimeout(() => {
        if (this.binScanned === this.mealScanned) {
          this.mealScanned = false;
          this.binScanned = false;
        } else {
          this.binScanned = false;
        }
        this.timeout = false;
      }, 3000);
    },
    simulateScan() {
    },
    beep() {
      new Audio(beep).play();
    },
    error() {
      new Audio(error).play();
      try {
        navigator.vibrate(200);
      } catch (e) {
        console.error('vibrate failed', e);
      }
    },

    async connect() {

      let devices = await navigator.hid.getDevices();
      devices.forEach((device) => {
        console.log(`HID: ${device.productName}`);
      });
      console.log('devices', devices)


      const [device] = await navigator.hid.requestDevice({filters: []});
      console.log('device', device);
      this.device = device;

      for (let collection of device.collections) {
        // An HID collection includes usage, usage page, reports, and subcollections.
        console.log(`Usage: ${collection.usage}`);
        console.log(`Usage page: ${collection.usagePage}`);

        for (let inputReport of collection.inputReports) {
          console.log(`Input report: ${inputReport.reportId}`);
          // Loop through inputReport.items
        }

        for (let outputReport of collection.outputReports) {
          console.log(`Output report: ${outputReport.reportId}`);
          // Loop through outputReport.items
        }

        for (let featureReport of collection.featureReports) {
          console.log(`Feature report: ${featureReport.reportId}`);
          // Loop through featureReport.items
        }

        // Loop through subcollections with collection.children
      }

      // Wait for the HID connection to open.
      await device.open().catch(e => console.error('failed to open:', e.message));

      // Request feature report.
      const dataView = await device.receiveFeatureReport(/* reportId= */ 1);

      console.log('dataview', dataView);

      // Blink!
      // const waitFor = duration => new Promise(r => setTimeout(r, duration));
      // const reportId = 1;
      // for (let i = 0; i < 10; i++) {
      //   // Turn off
      //   await device.sendFeatureReport(reportId, Uint32Array.from([0, 0]));
      //   await waitFor(100);
      //   // Turn on
      //   await device.sendFeatureReport(reportId, Uint32Array.from([512, 0]));
      //   await waitFor(100);
      // }

//       // First, send a command to enable vibration.
// // Magical bytes come from https://github.com/mzyy94/joycon-toolweb
//       const enableVibrationData = [1, 0, 1, 64, 64, 0, 1, 64, 64, 0x48, 0x01];
//       await device.sendReport(0x01, new Uint8Array(enableVibrationData));
//
// // Then, send a command to make the Joy-Con device rumble.
// // Actual bytes are available in the sample.
//       const rumbleData = [
//         /* … */
//       ];
//       await device.sendReport(0x10, new Uint8Array(rumbleData));


    }
  },
  computed: {
    ...mapState(['hideTopNav']),
  }
}
</script>


<style scoped>
.large-alert >>> .v-alert {
  font-size: xxx-large;
}
</style>